import React from 'react'

const WhiskyDesc = () => {
    return (
        <>
            <p>
                También conocida como crema irlandesa, 
                De textura cremosa, de color marrón claro o dorado tonalidad 
                que varía según la proporción de crema y whisky.
            </p>   
        </>
    )
}

export default WhiskyDesc
