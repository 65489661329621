import React from 'react'

const RonPlataDesc = () => {
    return (
        <>
            <p>
                With a slightly stronger flavour, the colour of this variety is due 
                to not having so much aging time.
            </p>  
        </>
    )
}

export default RonPlataDesc
