import React from 'react'

const CafeDesc = () => {
    return (
        <>
            <p>
                Delicado licor de café que puede ser consumido solo o como 
                ingrediente de postres o cocteles.
            </p>  
        </>
    )
}

export default CafeDesc
