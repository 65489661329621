import React from 'react'

import Error from '../components/ErrorPagina'

const ErrorPagina = () => {
    return (
        <>
            <Error/>
        </>
    )
}

export default ErrorPagina
