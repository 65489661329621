import React from 'react'

const TraguitoDesc = () => {
    return (
        <>
            <p>
                Producto obtenido mediante la fermentación alcohólica y destilación de la 
                caña de azúcar. Muy usado en cocteles de caipiriña y el tradicional canelazo.  
            </p>
        </>
    )
}

export default TraguitoDesc

