import React from 'react'
import JuegoSection from '../components/JuegoSection/indexEn';

const juego = () => {
    return (
        <div>
            <JuegoSection/>
        </div>
    )
}

export default juego;