import React from 'react'

const TripleSecDesc = () => {
    return (
        <>
            <p>
                De origen Frances, y es el resultado de la triple destilación de una mezcla 
                de cáscaras de naranjas dulces y amargas, maceradas.
            </p>
        </>
    )
}

export default TripleSecDesc
