import React from 'react'

const CocadaBelgaDesc = () => {
    return (
        <>
            <p>
                Ralladura de coco maduro con notas lácteas cremosas y fondo avainillado.
            </p>
        </>
    )
}

export default CocadaBelgaDesc