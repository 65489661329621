import React from 'react'

const MentaDesc = () => {
    return (
        <>
            <p>
                It’s very special flavour and colour are attractant, 
                are derived from Peppermint, a hybrid between good grass and mint.
            </p>  
        </>
    )
}

export default MentaDesc
