export const TituloTraguito = "TRAGUITO"
export const TituloTraguitoCana = "TRAGUITO CAÑA"
export const TituloTraguitoSinAzucar = "TRAGUITO SIN AZÚCAR"
export const TituloTraguitoTradicional = "TRAGUITO TRADICIONAL"
export const TituloTraguitoCanaTradicional = "TRAGUITO CAÑA TRADICIONAL"
export const TituloCacao = "CACAO Cold's"
export const TituloCafe = "CAFÉ Cold's"
export const TituloCuracao = "CURAÇAO Cold's"
export const TituloMenta = "MENTA Cold's"
export const TituloPinaColada ="PIÑA COLADA Cold's"
export const TituloWhisky ="CREMA DE WHISKY Cold's"
export const TituloAmaretto = "AMARETTO Cold's"
export const TituloTripleSec = "TRIPLE SEC Cold's"
export const TituloRonOro = "RON ORO Isla del Tesoro"
export const TituloRonPlata = "RON PLATA Isla del Tesoro"
export const TituloChirimoya = "CHIRIMOYA Cold's"
export const TituloCocadaBelga = "COCADA BELGA Cold's"
export const TituloGoldenNut = "GOLDEN NUT Cold's"
export const TituloRompope = "ROMPOPE Cold's"

export const SubtituloTraguito = "AGUARDIENTE DE CAÑA"
export const SubtituloTraguitoCana = ""
export const SubtituloTraguitoSinAzucar = ""
export const SubtituloRonOro = ""
export const SubtituloRonPlata = ""
export const SubtituloWhisky = ""
export const SubtituloPinaColada = ""
export const SubtituloAmaretto = ""
export const SubtituloTripleSec = ""
export const SubtituloMenta = ""
export const SubtituloCuracao = ""
export const SubtituloCafe = ""
export const SubtituloCacao = ""
export const SubtituloChirimoya = "Chirimoya Liquor"
export const SubtituloGoldenNut = "Golden Nut Liquor"
export const SubtituloCocadaBelga = "Cocada Belga Liquor"
export const SubtituloRompope = "Rompope Liquor"


export const SubtituloEnTraguito = "Sugar Cane Aguardiente" 
export const SubtituloEnTraguitoCana = "Sugar Cane Aguardiente" 
export const SubtituloEnTraguitoSinAzucar = "Sugar Cane Aguardiente" 
export const SubtituloEnRonOro = "Gold Rum"
export const SubtituloEnRonPlata = "Silver Rum"
export const SubtituloEnWhisky = "Whisky flavoured Crème"
export const SubtituloEnPinaColada = "Pineapple & coconut milk Crème"
export const SubtituloEnAmaretto = "Almond Flavoured Liquor"
export const SubtituloEnTripleSec = "Orange flavoured liquor"
export const SubtituloEnMenta = "Mint flavoured Liquor"
export const SubtituloEnCuracao = "Orange flavoured Sweet Liquor"
export const SubtituloEnCafe = "Coffee flavoured sweet liquor"
export const SubtituloEnCacao = "Cacao flavoured sweet liquor"
export const SubtituloEnChirimoya = "Chirimoya Liquor"
export const SubtituloEnGoldenNut = "Golden Nut Liquor"
export const SubtituloEnCocadaBelga = "Cocada Belga Liquor"
export const SubtituloEnRompope = "Rompope Liquor"