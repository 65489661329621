import React from "react";
import SendGrid from "../components/Sendgrid"

const Sendgrid = () => {
    return (
        <>
           <SendGrid/> 
        </>
    )
}

export default Sendgrid;
