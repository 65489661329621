import React from 'react'

const TraguitoCanaDesc = () => {
    return (
        <>
            <p>
                Slightly sweet cane Aguardiente that maintains all it’s traditional 
                flavors and is the basis for the preparation of a variety of cocktails.
            </p>  
        </>
    )
}

export default TraguitoCanaDesc
