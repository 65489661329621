import React from 'react'

const CacaoDesc = () => {
    return (
        <>
            <p>
                Un excelente aperitivo que con su envolvente dulzor y perfumado
                aroma se presta para realizar las más exóticas recetas.
            </p>  
        </>
    )
}

export default CacaoDesc

