import React from 'react'

const ChirimoyaDesc = () => {
    return (
        <>
            <p>
                Creamy and riped Chirimoya with a light floral taste.
            </p>  
        </>
    )
}

export default ChirimoyaDesc
