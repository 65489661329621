import React from 'react'

const TraguitoCanaDesc = () => {
    return (
        <>
            <p>
                Aguardiente de caña ligeramente dulce que mantiene los sabores 
                tradicionales y es la base para la preparación de un sin número de cocteles.
            </p>  
        </>
    )
}

export default TraguitoCanaDesc
