import React from 'react'

const RompopeDesc = () => {
    return (
        <>
            <p>
                Notas lácteas cremosas con fondo sabor a ron dulce.
            </p>
        </>
    )
}

export default RompopeDesc