import React from 'react'

const MentaDesc = () => {
    return (
        <>
            <p>
                Sabor muy especial y su color muy atrayente, derivado de la menta 
                pipertia un hibrido entre la hierba buena y menta.
            </p>  
        </>
    )
}

export default MentaDesc
