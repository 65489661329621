import React from 'react'

const CuracaoDesc = () => {
    return (
        <>
            <p>
                Liquor made by maceration in alcohol of the bitter rinds of a 
                variety of oranges from the island of Curaçao, 
                therefore it’s particular name.
            </p>  
        </>
    )
}

export default CuracaoDesc
