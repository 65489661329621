import React from 'react'
import ElegirJuego from '../components/ElegirJuego' 

const SelecionarJuego = () => {
    return (
        <div>
            <ElegirJuego/>
        </div>
    )
}

export default SelecionarJuego
