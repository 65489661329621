import React from 'react'

const CocadaBelgaDesc = () => {
    return (
        <>
            <p>
                Ripe coconut zest with creamy dairy notes and vainilla base.
            </p>  
        </>
    )
}

export default CocadaBelgaDesc