import React from 'react'

const RonPlataDesc = () => {
    return (
        <>
            <p>
                Con un sabor ligeramente más fuerte, el color de esta variedad se debe a que no tiene tanto tiempo de crianza.
            </p>  
        </>
    )
}

export default RonPlataDesc
