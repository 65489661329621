import React from 'react'

const CuracaoDesc = () => {
    return (
        <>
            <p>
                Licor elaborado por maceración en alcohol de las cortezas amargas de una variedad 
                de naranjas amargas provenientes de la isla Curacao, de allí su nombre. 
            </p>  
        </>
    )
}

export default CuracaoDesc
