import React from 'react'

const CafeDesc = () => {
    return (
        <>
            <p>
                Delicate coffee liqueur that can be consumed alone or as
                ingredient for desserts or cocktails.
            </p>  
        </>
    )
}

export default CafeDesc
