import React from 'react'

const ChirimoyaDesc = () => {
    return (
        <>
            <p>
                Chirimoya cremosa y madura con leve fondo floral.
            </p>
        </>
    )
}

export default ChirimoyaDesc