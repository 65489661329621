import React from 'react'

const TraguitoSinAzucarDesc = () => {
    return (
        <>
            <p>
                Aguardiente de anís que se puede consumir solo, en shots o cócteles. Combina muy bien con cítricos.
            </p>
        </>
    )
}

export default TraguitoSinAzucarDesc
