import React from 'react'
import Idioma from '../components/Idioma';

const idiomaPagina = () => {
    return (
        <>
            <Idioma/>  
        </>
    )
}

export default idiomaPagina;
