import React from 'react'

const PinaColadaDesc = () => {
    return (
        <>
            <p>
                Este cóctel ganó fama en Puerto Rico desde 1978, 
                convirtiéndose en una de las bebidas más populares del mundo. 
            </p>  
        </>
    )
}

export default PinaColadaDesc
