import React from 'react'
import JuegoSection from '../components/JuegoHeavySection/indexEn';

const juego = () => {
    return (
        <div>
            <JuegoSection/>
        </div>
    )
}

export default juego;