import React from 'react'
import Filtro from '../components/Filtro/indexEn'
const english = () => {
    return (
        <div>
           <Filtro/>
        </div>
    )
}

export default english
