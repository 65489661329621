import React from 'react'
import Filtro from '../components/Filtro/'

const espanol = () => {
    return (
        <div>
           <Filtro/>
        </div>
    )
}

export default espanol
