import React from 'react'

const GoldenNutDesc = () => {
    return (
        <>
            <p>
                Notas lácteas y cítricas de cáscara de naranja contrastadas con nuez y 
                caramelo.
            </p>
        </>
    )
}

export default GoldenNutDesc