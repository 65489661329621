import React from 'react'

const TraguitoSinAzucarDesc = () => {
    return (
        <>
            <p>
                Aniseed Aguardiente that can be consumed 
                it by itself, on shots or cocktails. Combines very well with citrus fruits.
            </p>
        </>
    )
}

export default TraguitoSinAzucarDesc
