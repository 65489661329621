import React from 'react'

const CacaoDesc = () => {
    return (
        <>
            <p>
                An excellent aperitif that with its enveloping sweetness and fragrance
                aroma lends itself to making the most exotic recipes.
            </p>  
        </>
    )
}

export default CacaoDesc

