import React from 'react'

const RonOroDesc = () => {
    return (
        <>
            <p>
                El color dorado de este ron se obtiene gracias a las barricas de roble 
                donde reposa. Cuanto más tiempo esté almacenado, más oscuro será.
            </p>  
        </>
    )
}

export default RonOroDesc
