import React from 'react'

const GoldenNutDesc = () => {
    return (
        <>
            <p>
                Empty.
            </p>  
        </>
    )
}

export default GoldenNutDesc