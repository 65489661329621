import React from 'react'

const PinaColadaDesc = () => {
    return (
        <>
            <p>
                This cocktail gained fame in Puerto Rico since 1978, becoming 
                one of the most popular drinks in the world. 
            </p>  
        </>
    )
}

export default PinaColadaDesc
